"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@/store");
var role_config_1 = require("@/common/configs/role.config");
var singleSpa_service_1 = require("@/common/services/singleSpa.service");
//
var hasEditRole = function (role) {
    role = role || store_1.default.state.main.profileInfo.currentUserRole;
    var roles = role_config_1.default.roles;
    var roleItem = role && roles.find(function (item) { return item.key === role.toLocaleLowerCase(); });
    return roleItem && roleItem.canEdit;
};
/**
 * 判断用户身份
 * @param {Array<string>} roles - 要判断的身份列表
 */
var includeRoleWithStore = function (key, role) {
    var roleLimited = role_config_1.default.roleLimited;
    return roleLimited.includes(key) ? hasEditRole(role) : true;
};
var changeRouterToCampaignReport = function () {
    (0, singleSpa_service_1.changeAppRouter)('ExperienceList');
};
exports.default = {
    hasEditRole: hasEditRole,
    includeRoleWithStore: includeRoleWithStore,
    changeRouterToCampaignReport: changeRouterToCampaignReport
};
