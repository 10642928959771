<template>
    <div :class="$style.account">
        <div :class="$style.name" @click="handleNameClick" v-if="profileInfo">
            <div :class="$style.profileName">
                <span>{{ profileInfo.name }}</span>
                <pt-icon
                    icon="pt-icon--more"
                    :icon-style="{
                        width: '12px',
                        height: '12px',
                        fill: '#8993a4'
                    }"
                    :class="$style.arrow"
                ></pt-icon>
            </div>

            <div :class="$style.email">{{ accountName }}</div>
        </div>
        <div :class="$style.menu" v-if="visibleDropdown" v-pt-clickoutside="hideDropdown">
            <div :class="$style.item" @click="handleSettingClick">{{ $t('account.settings') }}</div>

            <div :class="$style.line"></div>
            <div :class="$style.profile">
                <div :class="$style['profile-icon']">{{ profileInfo.name[0] }}</div>
                <div :class="$style['profile-name']">{{ profileInfo.name }}</div>
                <div :class="$style.profiles" v-if="profiles.length > 1">
                    <pt-icon
                        icon="pt-icon--more"
                        :icon-style="{
                            width: '18px',
                            height: '18px',
                            fill: '#212121'
                        }"
                    ></pt-icon>
                    <ul :style="spaceListStyle">
                        <pt-scrollbar>
                            <template v-for="(item, $index) in profiles">
                                <li
                                    v-if="item.sid !== profileInfo.sid"
                                    :key="$index"
                                    @click="handleProfileChange(item)"
                                >
                                    {{ $t('account.switch', { profile: item.name }) }}
                                </li>
                            </template>
                        </pt-scrollbar>
                    </ul>
                </div>
            </div>
            <div :class="$style.item" @click="handleProfSettingClick">{{ $t('account.project_plan') }}</div>
            <div :class="$style.line"></div>
            <div :class="$style.item" @click="handleLogoutClick">{{ $t('account.log_out') }}</div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import accountApis from '@/apis/account.apis';
import roleUtils from '@/common/utils/role';
import userService from '@/common/services/user.service';
import analyticsService from '@/common/services/analytics.service';

export default {
    name: 'Account',

    props: {
        userInfo: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            visibleDropdown: false
        };
    },

    computed: {
        ...mapState('main', ['profiles', 'profileInfo', 'profileCollaborativeUser']),

        accountName() {
            return this.userInfo.email;
        },

        spaceListStyle() {
            const padding = 10;
            const itemHeight = 32;
            const maxLength = 8;
            return {
                height: itemHeight * Math.min(maxLength, this.profiles.length - 1) + padding + 'px'
            };
        }
    },

    created() {
        const profileId = this.$route.params.sid;
        this.fetchProfileInfo(profileId);
    },

    methods: {
        ...mapActions('main', ['getProfileInfo']),

        fetchProfileInfo(sid, changeRouter = false) {
            this.getProfileInfo(sid).then(
                profile => {
                    changeRouter && this.changeRouter(profile);
                },
                err => {
                    console.log('get profile info failed.');
                }
            );
        },

        handleNameClick() {
            this.visibleDropdown = !this.visibleDropdown;
        },

        handleLogoutClick() {
            accountApis.logout();
            userService.logout();
        },

        handleProfileChange(profile) {
            this.fetchProfileInfo(profile.sid, true);
            this.hideDropdown();
        },

        handleSettingClick() {
            this.$eventBus.$emit('visibleMainPopup', {
                type: 'accountSetting',
                visible: true
            });
            this.hideDropdown();
        },
        handleProfSettingClick() {
            this.$router.push({ name: 'PlanUsage' });
            this.hideDropdown();
        },
        hideDropdown() {
            this.visibleDropdown = false;
        },
        changeRouter(profile = {}) {
            const { domain, name, status, sid } = profile;
            if (sid) {
                roleUtils.changeRouterToCampaignReport();
                this.$eventBus.$emit('changeProfile');
                analyticsService
                    .identifyUser(
                        {
                            id: this.userInfo.id
                        },
                        {
                            last_project_name: name,
                            last_project_domain: domain,
                            last_project_is_active: status
                        }
                    )
                    .track();
            }
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

:global {
    // ================================================
    // Transitions
    // ================================================
    .account-menu--transition-enter-active,
    .account-menu--transition-leave-active {
        //   transition: all 0.1s ease;
        transform-origin: top 0px;
        transition: transform ease 0.2s, opacity ease 0.2s, top ease 0.2s;
    }

    .account-menu--transition-enter,
    .account-menu--transition-leave {
        opacity: 0;
        transform: scaleY(0);
    }
}

.account {
    position: relative;

    &.align-center {
        .name {
            padding: 28px 0;
        }
    }

    .name {
        font-size: 12px;
        color: $pt-black-90;
        line-height: 1.4;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        position: relative;
        padding: 0 0 0 20px;
        max-width: 196px;

        .arrow {
            margin-left: 10px;
            transform: rotate(90deg);
        }

        &:hover {
            .profileName,
            .email {
                color: $pt-white;
            }
            .arrow {
                fill: $pt-white !important;
            }
        }

        .profileName {
            display: flex;
            align-items: center;
            font-weight: 600;
            span {
                @extend %text-ellipsis;
            }
        }

        .email {
            @extend %text-ellipsis;
        }
    }

    .menu {
        position: absolute;
        width: 260px;
        border-radius: 4px;
        background-color: $pt-white;
        box-shadow: 0 2px 6px 0 rgba(93, 105, 119, 0.2);
        border: 1px solid $pt-black-50;
        padding: 15px 13px;
        top: 100%;
        left: 0;
        z-index: 20;
        margin: 10px 0 0 0;
        font-size: 13px;
    }

    .line {
        height: 1px;
        line-height: 1px;
        background: $pt-black-30;
        margin-top: 6px;
        margin-bottom: 6px;

        & + .line {
            display: none;
        }
    }

    .item {
        line-height: 26px;
        height: 26px;
        color: $pt-black-700;
        cursor: pointer;
        margin-bottom: 6px;
        text-indent: 6px;

        &.account {
            margin-top: 6px;
            cursor: pointer;
            height: 26px;
            color: $pt-black-700;
            font-size: 13px;
            line-height: 26px;
            margin-left: 7px;
            margin-bottom: 8px;
        }
        &:hover {
            color: $pt-black-700;
            border-radius: 4px;
            background: $pt-black-40;
        }
        &.last-child {
            margin-bottom: 6px;
        }
        &.has-border-top {
            height: 1px;
            line-height: 1px;
            background: $pt-black-30;
            margin-bottom: 6px;
        }
    }

    .profile {
        display: flex;
        align-items: center;
        height: 50px;
        margin-top: 12px;
        padding-left: 10px;
        margin-bottom: 12px;
        position: relative;
        cursor: pointer;

        &:hover .profiles ul {
            display: block;
        }

        &-icon {
            width: 50px;
            height: 50px;
            background-color: $pt-black-50;
            font-size: 34px;
            border-radius: 4px;
            line-height: 46px;
            text-align: center;
            color: $pt-black-700;
            margin: 0 auto;
        }

        &-name {
            flex-grow: 1;
            padding-left: 15px;
            width: 138px;
            font-size: 16px;
            font-weight: bold;
            color: $pt-black-700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .profiles {
            ul {
                display: none;
                width: 230px;
                top: 0;
                left: 230px;
                position: absolute;
                padding: 5px 0 5px 10px;
                border-radius: 4px;
                background-color: white;
                border: solid 1px $pt-black-50;
                box-shadow: 0 2px 6px 0 rgba(93, 105, 119, 0.2);
                height: 200px;
                margin-bottom: 0;
                li {
                    font-weight: normal;
                    height: 26px;
                    line-height: 26px;
                    margin-bottom: 6px;
                    padding-left: 6px;
                    cursor: pointer;
                    color: $pt-black-700;
                    @extend %text-ellipsis;
                    &:hover {
                        color: $pt-black-700;
                        border-radius: 4px;
                        background: $pt-black-20;
                    }
                }
                li:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
