import { render, staticRenderFns } from "./Onboarding.vue?vue&type=template&id=e26d64b0"
import script from "./Onboarding.vue?vue&type=script&lang=js"
export * from "./Onboarding.vue?vue&type=script&lang=js"
import style0 from "./Onboarding.vue?vue&type=style&index=0&id=e26d64b0&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.23_cache-loader@4.1.0_webpack@4.47.0_webpack-cli@3.3_lx7resl4iexfrxrlamle6k6hri/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports