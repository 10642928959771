<template>
    <div :class="$style.usersgroupempty">
        <div :class="$style.banner">
            <div :class="$style.toppic"></div>
            <div :class="$style.title">{{ $t('users.empty_title') }}</div>
            <div :class="$style.desc">
                {{ $t('users.empty_description') }}
            </div>

            <el-tooltip :disabled="hasEditRole">
                <div :class="$style.footer">
                    <pt-button size="medium" :disabled="!hasEditRole" @click="handleClick">
                        {{ $t('users.empty_create') }}
                    </pt-button>
                </div>

                <div class="no-access-tips" slot="content">
                    <span>{{ $t('user_setting.usergroup_no_access') }}</span>
                </div>
            </el-tooltip>
        </div>
    </div>
</template>
<script>
import { checkModuleRole } from '@/common/services/singleSpa.service';

export default {
    name: 'UsersGroupEmpty',
    data() {
        return {
            hasEditRole: checkModuleRole('userGroupAdd'),
            contentList: [
                {
                    icon: 'icon-tick-mark',
                    title: 'users.empty_tick_a_title',
                    desc: 'users.empty_tick_a_desc'
                },
                {
                    icon: 'icon-tick-mark',
                    title: 'users.empty_tick_b_title',
                    desc: 'users.empty_tick_b_desc'
                },
                {
                    icon: 'icon-tick-mark',
                    title: 'users.empty_tick_c_title',
                    desc: 'users.empty_tick_c_desc'
                }
            ]
        };
    },
    methods: {
        handleClick() {
            this.$router.push({ name: 'UsersReport' });
        },

        gotoUserGroup() {
            this.$router.push({ name: 'UsersSettingReport', params: { usersId: 'create' } });
        }
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
.usersgroupempty {
    height: 100vh;
    background-color: $pt-white;
    .banner {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;

        @include media-sm {
            .toppic {
                height: 150px;
            }
        }

        .toppic {
            width: 57.58%;
            max-width: 730px;
            height: 230px;
            margin-top: 80px;
            background: url(~@assets/images/users/user-empty.png) no-repeat left center;
            background-size: contain;
        }
        .title {
            width: 80%;
            color: $pt-black-900;
            text-align: center;
            margin: 40px auto 15px;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 36px;
        }
        .desc {
            width: 80%;
            max-width: 640px;
            text-align: center;
            color: $pt-black-900;
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20px;
        }
        .footer {
            margin: 18px 0 0;
            button {
                width: 288px;
            }
        }
    }
}
</style>
