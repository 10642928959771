import { formatStyleObjectToString, StyleObject } from '../../style-object';
import { highlightEle } from '../highlight';
import { isDOMElement } from '../../../utils/isDOMElement';
import { stop } from '../index';

let defaultMaskStyle: StyleObject = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'transparent',
    zIndex: 9999,
    transition: 'all .3s ease',
    contain: 'strict'
};

let targetEle: HTMLElement | null = null;
let clickMaskToStopFlag: boolean | undefined = false;
let maskEleExist = false;
export let maskEle: HTMLElement | null = null;
let clickTargetFunc: undefined | ((e?: MouseEvent) => void);
let clickMaskFunc: undefined | ((e?: MouseEvent) => void);
let clickWhiteListFunc: undefined | ((e?: MouseEvent) => void);
let whiteList: Array<string> = [];
let staticWhiteList: Array<HTMLElement> = [];
let dynamicWhiteList: Array<HTMLElement> = [];

export function createMask(
    target: HTMLElement | null,
    config: {
        styleObj?: StyleObject;
        interactive?: boolean;
        clickable?: boolean;
        clickMaskToStop?: boolean;
        onClickTarget?: (e?: MouseEvent) => void;
        onClickMask?: (e?: MouseEvent) => void;
        onClickWhiteList?: (e?: MouseEvent) => void;
        whiteList?: Array<string | HTMLElement>;    
    }
) {
    targetEle = target;
    whiteList = [];
    staticWhiteList = [];
    dynamicWhiteList = [];

    clickMaskToStopFlag = config?.clickMaskToStop;
    clickTargetFunc = config?.onClickTarget;
    clickMaskFunc = config?.onClickMask;
    clickWhiteListFunc= config?.onClickWhiteList;
    [targetEle, highlightEle, '.guide-tooltip', ...(config?.whiteList ?? [])].forEach(whitelistDOM => {
        if (typeof whitelistDOM === 'string') whiteList.push(whitelistDOM);
        else if (isDOMElement(whitelistDOM)) staticWhiteList.push(whitelistDOM as HTMLElement)
    });
    if (!maskEle) maskEle = document.createElement('div');
    else maskEleExist = true;

    maskEle.style.cssText = formatStyleObjectToString({
        ...defaultMaskStyle,
        ...config?.styleObj,
        cursor: config?.clickMaskToStop ? 'pointer': 'default',
        pointerEvents: config?.interactive ? 'none' : 'auto'
    });

    if (!maskEleExist) document.body.append(maskEle);

    if (!config?.clickable) {
        document.addEventListener('click', stopClickPropagation, true);
        document.addEventListener('mousedown', stopMouseDownPropagation, true);
        document.addEventListener('keydown', stopKeyboardEvent);
    }
}

export function deleteMask() {
    if(maskEle) {
        maskEle.remove();
        maskEle = null;
        maskEleExist = false;
    }

    if (targetEle) targetEle = null;

    clickMaskToStopFlag = false;
    clickTargetFunc = undefined;
    clickMaskFunc = undefined;
    clickWhiteListFunc= undefined;
    whiteList = [];
    staticWhiteList = [];
    dynamicWhiteList = [];
    document.removeEventListener('click', stopClickPropagation, true);
    document.removeEventListener('mousedown', stopMouseDownPropagation, true);
    document.removeEventListener('keydown', stopKeyboardEvent);
}

export function setDefaultMaskStyle(styleObj: StyleObject) {
    if (typeof styleObj !== 'object') return;
    defaultMaskStyle = { ...defaultMaskStyle, ...styleObj };
}

function stopClickPropagation(event: MouseEvent) {
    dynamicWhiteList = [];
    whiteList.forEach((domString) => {
        const DOM = Array.prototype.slice.call(document.querySelectorAll(domString));
        if (DOM.length) dynamicWhiteList.push(...DOM);
    });

    const allWhiteList = [...staticWhiteList, ...dynamicWhiteList, ];
    if (
        !allWhiteList.some(whiteListDOM => (whiteListDOM as HTMLElement)?.contains(event.target as Node))
    ) {
        event.preventDefault();
        event.stopPropagation();
        clickMaskFunc?.(event);
        if (clickMaskToStopFlag) stop();
        return;
    }

    for (let i = 0, len = allWhiteList.length; i < len; i++) {
        if (allWhiteList[i]?.contains(event.target as Node)) {
            if (i === 0) clickTargetFunc?.(event);
            else clickWhiteListFunc?.(event);
            return;
        }
    }
    event.preventDefault();
    event.stopPropagation();
}

function stopMouseDownPropagation(event: MouseEvent) {
    dynamicWhiteList = [];
    whiteList.forEach((domString) => {
        const DOM = Array.prototype.slice.call(document.querySelectorAll(domString));
        if (DOM.length) dynamicWhiteList.push(...DOM);
    });

    const allWhiteList = [...staticWhiteList, ...dynamicWhiteList, ];
    if (
        !allWhiteList.some(whiteListDOM => (whiteListDOM as HTMLElement)?.contains(event.target as Node))
    ) {
        event.preventDefault();
    }
}

function stopKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Tab')
        event.preventDefault();
}