var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.account},[(_vm.profileInfo)?_c('div',{class:_vm.$style.name,on:{"click":_vm.handleNameClick}},[_c('div',{class:_vm.$style.profileName},[_c('span',[_vm._v(_vm._s(_vm.profileInfo.name))]),_c('pt-icon',{class:_vm.$style.arrow,attrs:{"icon":"pt-icon--more","icon-style":{
                    width: '12px',
                    height: '12px',
                    fill: '#8993a4'
                }}})],1),_c('div',{class:_vm.$style.email},[_vm._v(_vm._s(_vm.accountName))])]):_vm._e(),(_vm.visibleDropdown)?_c('div',{directives:[{name:"pt-clickoutside",rawName:"v-pt-clickoutside",value:(_vm.hideDropdown),expression:"hideDropdown"}],class:_vm.$style.menu},[_c('div',{class:_vm.$style.item,on:{"click":_vm.handleSettingClick}},[_vm._v(_vm._s(_vm.$t('account.settings')))]),_c('div',{class:_vm.$style.line}),_c('div',{class:_vm.$style.profile},[_c('div',{class:_vm.$style['profile-icon']},[_vm._v(_vm._s(_vm.profileInfo.name[0]))]),_c('div',{class:_vm.$style['profile-name']},[_vm._v(_vm._s(_vm.profileInfo.name))]),(_vm.profiles.length > 1)?_c('div',{class:_vm.$style.profiles},[_c('pt-icon',{attrs:{"icon":"pt-icon--more","icon-style":{
                        width: '18px',
                        height: '18px',
                        fill: '#212121'
                    }}}),_c('ul',{style:(_vm.spaceListStyle)},[_c('pt-scrollbar',[_vm._l((_vm.profiles),function(item,$index){return [(item.sid !== _vm.profileInfo.sid)?_c('li',{key:$index,on:{"click":function($event){return _vm.handleProfileChange(item)}}},[_vm._v(" "+_vm._s(_vm.$t('account.switch', { profile: item.name }))+" ")]):_vm._e()]})],2)],1)],1):_vm._e()]),_c('div',{class:_vm.$style.item,on:{"click":_vm.handleProfSettingClick}},[_vm._v(_vm._s(_vm.$t('account.project_plan')))]),_c('div',{class:_vm.$style.line}),_c('div',{class:_vm.$style.item,on:{"click":_vm.handleLogoutClick}},[_vm._v(_vm._s(_vm.$t('account.log_out')))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }