<template>
    <div
        :class="$style.list"
        v-loading="loading"
        element-loading-background="transparent"
        :element-loading-text="loadingText"
    >
        <template v-if="!loading">
            <user-group-tips v-if="hasExceededData"></user-group-tips>

            <template v-else>
                <div class="x-wrap x-wrap--full" :class="$style.tools">
                    <el-tooltip :content="$t('users.user_report_export')" placement="top">
                        <pt-button
                            color="grey"
                            :icon="{ name: 'PTX-icon-CSVExport-20', width: '20px', height: '20px' }"
                            :class="$style.iconBtn"
                            @click="handleDownloadClick"
                            :loading="downloadLoading"
                            :disabled="downloadLoading || (tableBody && tableBody.length === 0)"
                        ></pt-button>
                    </el-tooltip>
                    <el-tooltip :content="$t('users.users_list_columns')" placement="top">
                        <pt-button
                            color="grey"
                            :icon="{
                                name: 'PTX-icon-manage-columns-20',
                                width: '20px',
                                height: '20px'
                            }"
                            :class="$style.iconBtn"
                            @click="showSort = true"
                        ></pt-button>
                    </el-tooltip>
                </div>

                <div class="x-wrap x-wrap--full" :class="$style.containerWrap" ref="table">
                    <div
                        v-if="queryError"
                        :class="$style.noData"
                        v-html="$t('error.common_500')"
                    ></div>

                    <template v-else>
                        <div :class="[$style.row, $style['row-header']]" :style="tableHeaderStyle">
                            <div
                                :class="[
                                    $style.cell,
                                    $style.canSort,
                                    sort.index === $index && $style.itemActive
                                ]"
                                v-for="(col, $index) in tableHeader"
                                :key="col.key"
                                :style="col.style"
                                :title="col.key"
                                @click="handleSortClick(col, $index)"
                            >
                                <template v-if="col.key">
                                    <span>{{ col.key }}</span>
                                    <div
                                        :class="[
                                            $style.sort,
                                            sort.index === $index && $style.colIsActive
                                        ]"
                                    >
                                        <div
                                            :class="[
                                                $style.sort_arrow,
                                                $style.arrow_asc,
                                                sort.type === 'asc' && $style.active
                                            ]"
                                        ></div>
                                        <div
                                            :class="[
                                                $style.sort_arrow,
                                                $style.arrow_desc,
                                                sort.type === 'desc' && $style.active
                                            ]"
                                        ></div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <template v-if="tableBody && tableBody.length > 0">
                            <div
                                v-for="(row, $index) in tableBody"
                                :class="$style.row"
                                :key="$index"
                            >
                                <div
                                    :class="$style.cell"
                                    v-for="(col, $colIndex) in row"
                                    :key="$colIndex"
                                    :style="tableHeader[$colIndex].style"
                                    :title="col"
                                >
                                    <span>{{ col }}</span>
                                </div>
                            </div>
                        </template>
                        <div v-else :class="$style.noData">
                            {{ $t('users.users_report_no_data') }}
                        </div>
                    </template>
                </div>
                <div
                    :class="$style.lenTips"
                    class="x-wrap x-wrap--full"
                    v-if="tableBody && tableBody.length > 0 && visibleCountTips"
                >
                    {{ $t('users.users_report_limit', { num: limit.listCount }) }}
                </div>
            </template>
        </template>
        <sort-column
            :visible="showSort"
            :columns="tableHeader"
            :default-columns="currentColumns"
            :showSort="showSort"
            :can-search="true"
            :need-reset="true"
            target="userlist"
            @reset="handleDoneClick('default')"
            @change="handleDoneClick"
            @close="showSort = false"
        ></sort-column>
    </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { debounce } from 'throttle-debounce';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import usersApis from '@/apis/users.apis';
import profileApis from '@/apis/profile.apis';
import commonUtils from '@/common/utils/common.utils';
import cloneUtils from '@/common/utils/clone.utils';
import csvUtils from '@/common/utils/csv.util';
import numberUtils from '@common/utils/number.utils';
import dateUtils from '@/common/utils/date.utils';
import VerifyPackage from '@/common/packages/verify-package/VerifyPackage';
import UserGroupTips from './UserGroupTips';
import SortColumn from '@/components/campaign/report/SortColumn';
import analyticsService from '@/common/services/analytics.service';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export default {
    name: 'UsersSettingList',

    data() {
        const defaultColumns = [
            'name',
            'email',
            'initial referring domain',
            'distinct user ID',
            'last active',
            'first seen',
            'city'
        ];
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        return {
            showSort: false,
            loading: true,
            downloadLoading: false,
            loadingText: '',
            loadingTextStore: commonUtils.loadingTextStore(),
            hasExceededData: false,
            queryDataExceededErrorCode: 'QUERY_DATA_EXCEEDED',
            axiosSource: source,
            tableHeader: null,
            tableHeaderStyle: {},
            tableBody: null,
            tableBodyOrigin: null, //原始数据
            currentSortIndex: 4,
            currentSortType: 'desc',
            canSort: true,
            queryError: false,

            sort: {
                index: 0,
                type: 'desc'
            },

            limit: {
                listCount: 200,
                downloadCount: 5000
            },

            paging: {
                initRows: 20, //初始显示条数
                itemHeight: 48, //当前每行高度
                accRows: 10, //滚动累加条数
                num: 1 //当前分页
            },

            propertiesStates: {
                searchKey: null,
                searchList: null,
                searchFocus: false,
                checkList: defaultColumns, //已确定选中项
                checkListModel: defaultColumns, //当前操作项
                defaultColumns,
                columnMinWidth: 150 //每列最小宽度
            }
        };
    },

    computed: {
        ...mapState('main', ['profileInfo', 'userInfo']),
        ...mapState('users', ['userGroupInfo', 'userProperties']),
        ...mapGetters('users', ['usersFilter', 'userGroupName']),

        wrapStyle() {
            const { columnMinWidth, checkList } = this.propertiesStates;
            const minWidth = checkList.length * columnMinWidth;
            return {
                'min-width': `${minWidth}px`
            };
        },

        canDownloadList() {
            const { source } = this.userGroupInfo || {};
            return source !== 'default';
        },

        visibleCountTips() {
            return this.tableBody.length === this.tableBodyOrigin.length;
        },

        currentColumns() {
            let { checkList, searchList } = this.propertiesStates;
            const selected = [];
            const unselected = searchList.filter(item => !checkList.includes(item.key));

            // 保持选中列表顺序展示
            checkList.forEach(key => {
                const item = searchList.find(o => o.key === key);
                item && selected.push(item);
            });

            selected.length > 0 && unselected.length > 0 && (unselected[0].divided = true);
            return [...selected, ...unselected];
        }
    },

    async created() {
        const [err, data] = await commonUtils.awaitWrap(
            profileApis.accountProfile(null, {
                where: {
                    sid: this.profileInfo.sid,
                    aid: this.userInfo.id
                }
            })
        );
        if (err) {
            console.log('query account profile failed.');
        }

        let { checkList, checkListModel, defaultColumns } = this.propertiesStates;
        const { userListColumns } = data || {};
        checkList = (userListColumns || defaultColumns).reduce((acc, cur) => {
            this.userProperties.some(item => item.key === cur) && acc.push(cur);
            return acc;
        }, []);
        this.$set(this.propertiesStates, 'checkList', checkList);
        this.$set(this.propertiesStates, 'checkListModel', [...checkList]);
        this.updateTableHeader();
        this.updateSortIndex();
        this.queryDebounce = debounce(200, false, () => {
            this.fetchUserList();
        });
        this.queryDebounce();
        this.$eventBus.$on('mainScroll', this.updateTableHeaderPosition, false);
        this.$eventBus.$on('mainScrollReachEndY', this.updateTableBody, false);
    },

    beforeDestroy() {
        this.$eventBus.$off('mainScroll', this.updateTableHeaderPosition, false);
        this.$eventBus.$off('mainScrollReachEndY', this.updateTableBody, false);
        this.loadingTextStore.stop();
        this.axiosSource.cancel();
    },

    methods: {
        ...mapActions('main', ['updateAccountProfile']),
        ...mapMutations('main', ['SET_AUTH_DIALOG_VISIBLE']),
        showAuthDialog() {
            this.SET_AUTH_DIALOG_VISIBLE({ visible: true, type: 'USE_CSV_DOWNLOAD' });
        },

        requestUserList(limit, sort, order) {
            const properties = this.tableHeader.map(item => {
                const { key, propertyType, patternType } = item;
                return {
                    key,
                    type: propertyType,
                    patternType: patternType.replace(/\-A|\-B|\-C|\-D/g, '')
                };
            });
            const where = {
                sid: this.profileInfo.sid,
                funName: 'userList',
                audienceFilter: this.usersFilter,
                timeZone: this.profileInfo.timeZone,
                limit,
                properties
            };
            sort && (where.sort = sort);
            order && (where.order = order);
            return usersApis.detailData(
                null,
                {
                    where
                },
                { cancelToken: this.axiosSource.token }
            );
        },

        async fetchUserList() {
            const { index, type } = this.sort;
            const sortKey = this.tableHeader[index] && this.tableHeader[index].key;
            this.loading = true;
            this.queryError = false;
            this.axiosSource.cancel();
            this.loadingTextStore.start(() => {
                this.loadingText = this.$t('users.query_data_large_loading');
            });
            const [err, detailData] = await commonUtils.awaitWrap(
                this.requestUserList(this.limit.listCount, sortKey, type)
            );

            if (err || !detailData) {
                this.tableBodyOrigin = [];
                this.queryError = true;
                this.hasExceededData = err?.code === this.queryDataExceededErrorCode;
            } else if (!axios.isCancel(detailData)) {
                const { user_list } = detailData;
                this.tableBodyOrigin =
                    (user_list &&
                        user_list.map(item => {
                            return this.tableHeader.reduce((acc, cur) => {
                                const { patternType } = cur;
                                let val = item[cur.key];
                                if (patternType.includes('TIME')) {
                                    try {
                                        // 时间戳类型
                                        if (val && val.length === 13) {
                                            const date = new Date(Number(val));
                                            !Number.isNaN(date.getTime()) &&
                                                (val = dayjs(date).fromNow());
                                        }
                                    } catch (e) {
                                        console.log(e);
                                    }
                                }
                                const backupVal = cur.backup && item[cur.backup.key];
                                acc.push(val || backupVal || '--');
                                return acc;
                            }, []);
                        })) ||
                    [];
            }
            this.updateTableBody(true);
            this.loading = false;
            this.loadingTextStore.stop();
        },

        updateTableHeader() {
            const { checkList, defaultColumns } = this.propertiesStates || { checkList: [] };
            const showList = checkList.length > 0 ? checkList : defaultColumns;
            const len = showList.length;
            const itemWidth = numberUtils.strip(1 / len) * 100;

            this.tableHeader = showList.reduce((acc, cur, index) => {
                const itemInfo = this.userProperties.find(item => item.key === cur);
                itemInfo &&
                    acc.push(
                        Object.assign({}, itemInfo, {
                            style: { flex: index === 0 ? '1' : `0 0 ${itemWidth}%` }
                        })
                    );
                return acc;
            }, []);
        },

        updateSortIndex() {
            const defaultSortKey = 'last active';
            const defaultSortKeyIndex = this.tableHeader.findIndex(
                item => item.key === defaultSortKey
            );
            this.sort.index = defaultSortKeyIndex === -1 ? 0 : defaultSortKeyIndex;
        },

        handleDropdownChange(visible) {
            visible && this.$set(this.propertiesStates, 'searchKey', '');
        },

        handleDoneClick(change) {
            console.log('type', change);
            const { checkListModel, defaultColumns } = this.propertiesStates;
            let checkList = [...defaultColumns];
            if (change === 'default') {
                this.$set(this.propertiesStates, 'checkListModel', [...defaultColumns]);
            } else {
                checkList = change.map(c => c.name);
            }

            this.$set(this.propertiesStates, 'checkList', checkList);
            this.$set(this.propertiesStates, 'checkListModel', [...checkList]);
            this.updateTableHeader();
            this.updateSortIndex();
            this.queryDebounce();
            this.updateAccountProfile({
                key: 'userListColumns',
                val: checkList
            });
        },

        handleCanceClick() {
            this.$refs.columnsDropdown && (this.$refs.columnsDropdown.visible = false);
        },

        handleSortClick(col, index) {
            const type =
                this.sort.index === index ? this.opposites(this.sort.type) : this.sort.type;
            this.sort.index = index;
            this.sort.type = type;
            this.queryDebounce();
        },

        async handleDownloadClick() {
            this.axiosSource.cancel();
            this.downloadLoading = true;
            const chartHeader = this.tableHeader.map(item => item.name);
            const [err, detailData] = await commonUtils.awaitWrap(
                this.requestUserList(this.limit.downloadCount)
            );
            if (err) {
                this.$message.error('Fetch chart data fail!');
                this.downloadLoading = false;
            } else if (!axios.isCancel(detailData)) {
                const { user_list } = detailData;
                const chartData = [chartHeader].concat(
                    (user_list &&
                        user_list.map(item => {
                            return this.tableHeader.map(row => {
                                if (row.patternType.includes('TIME')) {
                                    try {
                                        // 时间戳类型
                                        if (item[row.key] && item[row.key].length === 13) {
                                            return dateUtils.formatTimezone(
                                                Number(item[row.key]),
                                                this.profileInfo.timeZone,
                                                'YYYY/MM/DD HH:mm:ss'
                                            );
                                        }
                                    } catch (e) {
                                        return item[row.key] || '--';
                                    }
                                }
                                return item[row.key] || '--';
                            });
                        })) ||
                        []
                );
                analyticsService.usePTX('ug_export', { position: 'ug_user_list' }).track();
                csvUtils.init('userList', this.userGroupName || 'userGroup', chartData).download();
                this.downloadLoading = false;
            }
        },

        opposites(direction) {
            var mapper = { desc: 'asc', asc: 'desc' };
            return direction ? mapper[direction] : 'desc';
        },

        handleSort(index) {
            const comparators = {
                number: function (a, b, direction) {
                    if (a === b) return 0;
                    return direction > 0 ? a - b : b - a;
                },

                string: function (a, b, direction) {
                    if (a === b) return 0;
                    // null特殊对应，以免和空值一起排序时发生列表交替显示的情况
                    var comparison = direction > 0 ? b === null || a > b : a === null || a < b;
                    return comparison === false ? -1 : comparison - 0;
                },

                time: function (a, b, direction) {
                    let aTime = new Date(a);
                    let bTime = new Date(b);
                    if (Number.isNaN(aTime)) return -1;
                    if (Number.isNaN(bTime)) return 1;
                    if ((aTime = aTime)) return 0;
                    return direction > 0 ? aTime < bTime : bTime > aTime;
                }
            };
            const directionMapper = { asc: 1, desc: -1 };
            const direction =
                this.currentSortIndex === index
                    ? this.opposites(this.currentSortType)
                    : this.currentSortType;
            const header = this.tableHeader[index];
            const dataType = header.dataType.toLowerCase() || 'string';
            this.currentSortIndex = index;
            this.currentSortType = direction;
            this.tableBody.sort((a, b) => {
                let comparator = comparators[dataType];
                return comparator.call(null, a, b, directionMapper[direction]);
            });
        },

        updateTableBody(isInit = false) {
            const list = this.tableBodyOrigin || [];
            const { initRows, itemHeight, accRows, num } = this.paging;
            if (isInit || list.length <= initRows) {
                this.tableBody = list.slice(0, initRows);
            } else {
                const startIndex = (num - 1) * accRows + initRows;
                const acc = list.slice(startIndex, startIndex + accRows);
                this.tableBody.push(...acc);
                this.paging.num = num + 1;
            }
        },

        updateTableHeaderPosition(scrollTop) {
            const tableDom = this.$refs.table;

            if (tableDom) {
                const tableDomTop = tableDom.getBoundingClientRect().top;
                const tableHeaderHeight = 60;
                const topButterBarHeight = document.querySelector('.js_butterBar') ? 40 : 0;
                this.tableHeaderStyle =
                    (tableDomTop <= 0 && {
                        top:
                            scrollTop -
                            tableDom.offsetTop -
                            tableHeaderHeight -
                            topButterBarHeight +
                            'px'
                    }) ||
                    {};
            }
        }
    },

    watch: {
        usersFilter: {
            handler: function (newVal, oldVal) {
                if (this.$route.name === 'UsersSettingList') {
                    this.tableHeader || this.updateTableHeader();
                    this.updateSortIndex();
                    this.queryDebounce();
                }
                this.hasExceededData = false;
            },
            deep: true
        },
        'propertiesStates.searchKey': {
            handler: function (val) {
                const list = cloneUtils.deep(this.userProperties || []);
                if (!val) {
                    this.propertiesStates.searchList = list;
                } else {
                    let debounceFn = debounce(0, () => {
                        this.propertiesStates.searchList = list.filter(item =>
                            item['key'].toLowerCase().includes(val.toLowerCase())
                        );
                        this.$refs.dropdownScrollbar &&
                            this.$refs.dropdownScrollbar.scrollToPos('top');
                    });
                    debounceFn();
                }
            },
            immediate: true
        },
        $route(val) {
            const { name } = val;
            if (name === 'UsersSettingList') {
                this.paging.num = 1;
                this.tableHeader || this.updateTableHeader();
                this.updateSortIndex();
                this.queryDebounce();
            }
            this.hasExceededData = false;
        }
    },

    components: {
        VerifyPackage,
        UserGroupTips,
        SortColumn
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

$tableHeaderHeight: 56px;

:global {
    .chart-list-dropdown {
        max-height: 400px;
        max-width: 302px;

        .pt-input {
            margin: 10px 20px;
            width: auto;
        }

        .pt-link {
            margin: 10px 20px;
        }

        &__trigger {
            cursor: pointer;
        }

        &__list {
            max-height: 220px;
        }

        &__item {
            &:hover {
                span {
                    color: $pt-green-70;
                }
            }
            .el-checkbox {
                display: flex;
                align-items: center;
                padding: 8px 0;
            }
            .el-checkbox__label {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
        }

        &__footer {
            border-top: 1px solid #cccccc;
            padding: 10px 10px 0;
            text-align: center;
            display: flex;
            justify-content: space-between;
        }
    }
}

.list {
    min-height: 60px;
    padding-bottom: $page-content-padding-bottom;

    .tools {
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-end;
        :global {
            .pt-button--contain--grey.is-loading-before::before {
                margin-right: 0;
            }
        }
        .iconBtn {
            width: 32px;
            margin-left: 8px;
            min-width: 0 !important;
        }

        /* .columns {
            margin-left: 30px;
        } */
    }

    .containerWrap {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 2px;
        border: 1px solid $pt-black-40;
        padding-top: $tableHeaderHeight;
    }

    .container {
        position: relative;
        flex: 1;
        overflow: hidden;
    }

    .row {
        color: $pt-black-600;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $pt-black-40;
        background-color: $pt-white;
        padding: 0;
        height: 88px;

        &-header {
            background-color: $pt-black-10;
            border-bottom: 1px solid $pt-black-90;
            height: $tableHeaderHeight;
            border-top: none;
            font-size: 13px;
            font-weight: 400;
            padding: 0;
            letter-spacing: 0;
            line-height: 19px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            .cell {
                display: flex;
                height: 100%;
                align-items: center;
                padding: 18px 8px;
                &.canSort {
                    cursor: pointer;

                    &:hover {
                        background: $pt-black-30;
                        .sort {
                            opacity: 1;
                            &:not(.col-active) {
                                .arrow_desc {
                                    border-color: $pt-black-300 transparent transparent transparent;
                                }
                            }
                            &.col-active {
                                .arrow_asc {
                                    border-color: transparent transparent $pt-black-300 transparent;
                                }
                                .arrow_desc {
                                    border-color: $pt-black-300 transparent transparent transparent;
                                }
                            }
                        }
                    }
                }
                &:not(.itemActive):hover .arrow {
                    background-color: $pt-black-30;
                    opacity: 1;
                }
            }

            span {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: $pt-black-300;
                margin: 0 !important;
                @extend %text-ellipsis;
            }

            .arrow {
                margin-left: 6px;
                background-color: transparent;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                @extend %flex-center;
                opacity: 0;

                &[sort='asc'] {
                    transform: rotate(180deg);
                }
            }
            .sort {
                opacity: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                margin-left: 8px;
                height: 20px;
                .sort_arrow {
                    border-width: 5px;
                    border-style: solid;

                    width: 10px;
                    height: 10px;
                    &.arrow_asc {
                        border-color: transparent transparent $pt-black-50 transparent;
                    }
                    &.arrow_desc {
                        border-color: $pt-black-50 transparent transparent transparent;
                        margin-top: 4px;
                    }
                }
            }
        }

        &:hover:not(.row-header) {
            background-color: $pt-black-30;
        }
    }

    .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
        padding: 34px 8px;
        // padding-right: 6px;

        &.icon {
            @extend %flex-center;
            padding: 0;

            span {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                color: $pt-white;
                font-size: 16px;
                letter-spacing: 0;
                @extend %flex-center;
            }
        }

        &.email {
            font-size: 16px;
        }

        &:first-child {
            padding-left: 24px;
        }
    }

    .itemActive {
        .sort {
            opacity: 1;
            .active.arrow_asc {
                border-color: transparent transparent $pt-green-60 transparent !important;
            }
            .active.arrow_desc {
                border-color: $pt-green-60 transparent transparent transparent !important;
            }
        }
    }

    .noData {
        text-align: center;
        height: 352px;
        background: $pt-white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lenTips {
        font-size: 12px;
        text-align: left;
        padding-top: 10px;
        color: $pt-black-60;
    }
}
</style>
