var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:_vm.$style.list,attrs:{"element-loading-background":"transparent","element-loading-text":_vm.loadingText}},[(!_vm.loading)?[(_vm.hasExceededData)?_c('user-group-tips'):[_c('div',{staticClass:"x-wrap x-wrap--full",class:_vm.$style.tools},[_c('el-tooltip',{attrs:{"content":_vm.$t('users.user_report_export'),"placement":"top"}},[_c('pt-button',{class:_vm.$style.iconBtn,attrs:{"color":"grey","icon":{ name: 'PTX-icon-CSVExport-20', width: '20px', height: '20px' },"loading":_vm.downloadLoading,"disabled":_vm.downloadLoading || (_vm.tableBody && _vm.tableBody.length === 0)},on:{"click":_vm.handleDownloadClick}})],1),_c('el-tooltip',{attrs:{"content":_vm.$t('users.users_list_columns'),"placement":"top"}},[_c('pt-button',{class:_vm.$style.iconBtn,attrs:{"color":"grey","icon":{
                            name: 'PTX-icon-manage-columns-20',
                            width: '20px',
                            height: '20px'
                        }},on:{"click":function($event){_vm.showSort = true}}})],1)],1),_c('div',{ref:"table",staticClass:"x-wrap x-wrap--full",class:_vm.$style.containerWrap},[(_vm.queryError)?_c('div',{class:_vm.$style.noData,domProps:{"innerHTML":_vm._s(_vm.$t('error.common_500'))}}):[_c('div',{class:[_vm.$style.row, _vm.$style['row-header']],style:(_vm.tableHeaderStyle)},_vm._l((_vm.tableHeader),function(col,$index){return _c('div',{key:col.key,class:[
                                _vm.$style.cell,
                                _vm.$style.canSort,
                                _vm.sort.index === $index && _vm.$style.itemActive
                            ],style:(col.style),attrs:{"title":col.key},on:{"click":function($event){return _vm.handleSortClick(col, $index)}}},[(col.key)?[_c('span',[_vm._v(_vm._s(col.key))]),_c('div',{class:[
                                        _vm.$style.sort,
                                        _vm.sort.index === $index && _vm.$style.colIsActive
                                    ]},[_c('div',{class:[
                                            _vm.$style.sort_arrow,
                                            _vm.$style.arrow_asc,
                                            _vm.sort.type === 'asc' && _vm.$style.active
                                        ]}),_c('div',{class:[
                                            _vm.$style.sort_arrow,
                                            _vm.$style.arrow_desc,
                                            _vm.sort.type === 'desc' && _vm.$style.active
                                        ]})])]:_vm._e()],2)}),0),(_vm.tableBody && _vm.tableBody.length > 0)?_vm._l((_vm.tableBody),function(row,$index){return _c('div',{key:$index,class:_vm.$style.row},_vm._l((row),function(col,$colIndex){return _c('div',{key:$colIndex,class:_vm.$style.cell,style:(_vm.tableHeader[$colIndex].style),attrs:{"title":col}},[_c('span',[_vm._v(_vm._s(col))])])}),0)}):_c('div',{class:_vm.$style.noData},[_vm._v(" "+_vm._s(_vm.$t('users.users_report_no_data'))+" ")])]],2),(_vm.tableBody && _vm.tableBody.length > 0 && _vm.visibleCountTips)?_c('div',{staticClass:"x-wrap x-wrap--full",class:_vm.$style.lenTips},[_vm._v(" "+_vm._s(_vm.$t('users.users_report_limit', { num: _vm.limit.listCount }))+" ")]):_vm._e()]]:_vm._e(),_c('sort-column',{attrs:{"visible":_vm.showSort,"columns":_vm.tableHeader,"default-columns":_vm.currentColumns,"showSort":_vm.showSort,"can-search":true,"need-reset":true,"target":"userlist"},on:{"reset":function($event){return _vm.handleDoneClick('default')},"change":_vm.handleDoneClick,"close":function($event){_vm.showSort = false}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }