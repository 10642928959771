<template>
    <el-dialog :title="$t('users.new_users')" :visible="true" width="440px" center @close="handleCancelClick">
        <div :class="$style.detailItem">
            <label>{{ $t('users.users_report_name') }}</label>
            <pt-input v-model="currentName"></pt-input>
        </div>
        <div :class="$style.detailItem">
            <label>{{ $t('common.description') }}</label>
            <pt-input :class="$style.textarea" type="textarea" :rows="6" v-model="currDescription"></pt-input>
        </div>

        <div slot="footer" class="dialog-footer">
            <pt-button color="gray" @click="handleCancelClick" class="pt-mr10">{{ $t('common.button_cancel') }}</pt-button>
            <pt-button color="green" @click="handleConfirmClick" :disabled="!currentName">{{
                $t('common.button_save')
            }}</pt-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'DetailsDialog',

    props: {
        userGroupInfo: Object
    },

    data() {
        const { name, description } = this.userGroupInfo || {};
        return {
            currentName: name,
            currDescription: description
        };
    },

    methods: {
        handleConfirmClick() {
            this.$emit('confirm', {
                name: this.currentName,
                description: this.currDescription
            });
        },

        handleCancelClick() {
            this.$emit('cancel');
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.detailItem {
    margin: 0 0 20px;

    label {
        font-size: 14px;
        margin: 0 0 10px;
        display: block;
        color: $pt-black-600;
        font-weight: 400;
    }

    .textarea {
        height: 140px;
    }
}
</style>
