















































import { mapGetters } from 'vuex';
import dataSettingApis from '@/apis/data-setting.apis';
import commonUtils from '@/common/utils/common.utils';
import SettingsTable from './table.vue';
import docsUrl from '@common/configs/docsUrl';
import permissionUtils from '@/common/utils/permission';
import PermissionConfig from '@/common/configs/permissions.config';
import { checkModuleRole, RoleModuleKey } from '@/common/services/singleSpa.service';

export default {
    name: 'PropertySettings',

    data() {
        const userPropertyUploadPermision = permissionUtils.includePermissionWithStore([
            PermissionConfig.userPropertyUpload
        ]);
        const moreOptions = [
            {
                name: this.$t('common.button_edit'),
                code: 'edit',
                roleKey: 'ptxUserPropertyUpdate'
            }
        ].filter(item => checkModuleRole(item.roleKey as RoleModuleKey));
        const tableHeader = [
            {
                code: 'name',
                sortCode: 'showName',
                name: this.$t('data_settings.list_name'),
                dataType: 'string',
                supportSortable: true,
                span: 8
            },
            {
                code: 'source',
                sortCode: 'showSource',
                name: this.$t('data_settings.list_source'),
                tips: this.$t('annotation.data_2', { link: docsUrl.dataSetting }),
                dataType: 'string',
                supportSortable: true,
                span: 4
            },
            {
                code: 'type',
                sortCode: 'showType',
                name: this.$t('data_settings.list_type'),
                canEdit: true,
                dataType: 'string',
                supportSortable: true,
                span: 4
            },
            {
                code: 'description',
                sortCode: 'showDescription',
                name: this.$t('common.description'),
                canEdit: true,
                dataType: 'string',
                supportSortable: true,
                span: 8
            }
            // {
            //     code: 'users',
            //     name: 'Users with property',
            //     dataType: 'string',
            //     supportSortable: true,
            //     span: 6
            // }
        ];
        const typeOptions = [
            {
                code: 'STRING',
                name: this.$t('data_settings.data_type_string')
            },
            {
                code: 'TIME',
                name: this.$t('data_settings.data_type_date')
            },
            {
                code: 'NUMBER',
                name: this.$t('data_settings.data_type_number')
            }
        ];
        const sourceOptions = [
            {
                code: 'code',
                name: this.$t('data_settings.data_source_code')
            },
            {
                code: 'experience',
                name: this.$t('data_settings.data_experience')
            },
            {
                code: 'api',
                name: 'API'
            },
            {
                code: 'default',
                name: this.$t('data_settings.data_source_default')
            },
            {
                code: 'engage',
                name: this.$t('data_settings.data_source_engage')
            }
        ];
        return {
            moreOptions,
            tableHeader,
            sourceOptions,
            typeOptions,
            tableBody: [],
            joinString: '_tmp', //备份字段连接字符串
            loading: true,
            docUrl: docsUrl.identifyUser,
            uploadUrl: '',
            extraData: null,
            uploadCsvDialogVisible: false,
            uploading: false,
            userPropertyUploadPermision
        };
    },

    created() {
        this.fetchUserProperties();
        this.userPropertyUploadPermision && this.preFetchS3SignedUrl();
    },

    computed: {
        ...mapGetters('main', ['profileInfo']),

        canEditList() {
            return this.tableHeader.filter(item => item.canEdit);
        },

        currentRouteIsMe() {
            return this.$route.name === 'UserPropertySettings';
        }
    },

    methods: {
        showUploadDialog() {
            this.uploadCsvDialogVisible = true;
        },
        onUploadSuccess(_, file) {
            this.uploading = false;
            const fileName = file.raw.name;
            dataSettingApis.createUserPropertiesSyncTask(null, {
                input: {
                    s3FileKey: this.extraData.key,
                    profileId: this.profileInfo.sid,
                    fileName
                }
            });
            this.uploadCsvDialogVisible = false;

            this.$alert(
                this.$t('data_settings.user_property_csv_upload_success_tips'),
                this.$t('common.title_confirm'),
                {
                    confirmButtonText: this.$t('common.button_confirm'),
                    callback: action => {
                        this.$refs.csvUploadRef.clearFiles();
                        // 上传成功重新初始化上传配置
                        this.preFetchS3SignedUrl();
                    }
                }
            );
        },
        onUploadError() {
            this.uploading = false;
            this.$message.error(this.$t('data_settings.user_property_csv_upload_error_tips'));
        },

        async onBeforeUpload(file) {
            // 截取长度1024
            // 仅支持导入csv格式的文件 Only .csv files are allowed

            if (file.type && file.type.toLowerCase() !== 'text/csv') {
                this.$message.error(this.$t('data_settings.user_property_csv_upload_format_check_tips'));
                return Promise.reject();
            }

            const partOfContent = await file.slice(0, 1024).text();
            const indexOfNextLine = partOfContent.indexOf('\n');
            const firstLine = partOfContent.substring(0, indexOfNextLine + 1);
            // 文件大小限制200M
            const isLt200M = file.size / 1024 / 1024 < 200;

            if (!isLt200M) {
                this.$message.error(this.$t('data_settings.user_property_csv_upload_exceed200M_tips'));
                return Promise.reject();
            }

            if (firstLine.toLowerCase().indexOf('user_id') === -1) {
                const confirmResult = await this.$confirm(
                    this.$t('data_settings.user_property_csv_upload_invalid_confirm'),
                    this.$t('common.title_confirm'),
                    {
                        distinguishCancelAndClose: true,
                        confirmButtonText: this.$t('common.button_confirm'),
                        cancelButtonText: this.$t('common.button_cancel'),
                        cancelButtonClass: 'user-property-setting_cancel_tips'
                    }
                );
                if (confirmResult !== 'confirm') {
                    return Promise.reject();
                }
            }
            this.uploading = true;

            return true;
        },

        async preFetchS3SignedUrl() {
            const [err, res] = await commonUtils.awaitWrap(
                dataSettingApis.generateUploadSignedUrl(null, {
                    input: {
                        fileType: 'CSV',
                        fileExtensionName: 'CSV',
                        profileId: this.profileInfo.sid
                    }
                })
            );

            if (!err) {
                const { url, fields } = res;
                this.uploadUrl = url;
                this.extraData = fields;
            }
        },

        async fetchUserProperties() {
            const [err, res] = await commonUtils.awaitWrap(
                dataSettingApis.getUserProperties(null, {
                    where: {
                        sid: this.profileInfo.sid
                    }
                })
            );
            if (err) {
                this.currentRouteIsMe && console.log('get user properties failed.');
            } else {
                this.tableBody = res.map(event => {
                    event.rowEditing = false;
                    Object.keys(event).forEach(key => {
                        const eventInfo = this.tableHeader.find(item => item.code === key);
                        if (eventInfo) {
                            event[eventInfo.sortCode] = this.getCellVal(key, event[key]);
                        }
                    });

                    // 备份可编辑项数据
                    Object.keys(event).forEach(key => {
                        const canEdit = this.canEditList.some(item => item.code === key);
                        canEdit && (event[`${key}${this.joinString}`] = event[key]);
                    });
                    return event;
                });
            }
            this.loading = false;
        },

        async updateUserProperty({ id, name, type, displayName, source, description }) {
            const [err, res] = await commonUtils.awaitWrap(
                dataSettingApis.updateUserProperty(null, {
                    data: {
                        name,
                        displayName,
                        source,
                        type,
                        description
                    },
                    where: {
                        name,
                        sid: this.profileInfo.sid,
                        source
                    }
                })
            );
            err && this.currentRouteIsMe && this.$message.error(this.$t('error.update_user_property'));
        },

        getCellVal(code, value) {
            if (code === 'type') {
                const info = this.typeOptions.find(item => item.code === value.toLocaleUpperCase());
                return (info && info.name) || value;
            } else if (code === 'source') {
                return (value || [])
                    .map(i => {
                        var sourceInfo = this.sourceOptions.find(item => item.code === i);
                        return (sourceInfo && sourceInfo.name) || i;
                    })
                    .join();
            }
            return value;
        }
    },

    components: {
        SettingsTable
    }
};
