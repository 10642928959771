var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"},on:{"after-enter":_vm.afterEnter,"after-leave":_vm.afterLeave,"before-leave":_vm.beforeLeave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],ref:"dialog",class:_vm.$style.popup_wrapper},[_c('div',{ref:"dialogRef",class:[
                _vm.$style['pt-dialog'],
                {
                    'pt-dialog--center': _vm.center
                },
                _vm.$style[("pt-dialog__" + _vm.size)],
                _vm.customClass
            ],style:(_vm.style),attrs:{"aria-modal":"true","role":"dialog","aria-label":_vm.title || 'dialog'},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{class:_vm.$style['pt-dialog__header'],style:({
                    paddingBottom: ((_vm.hasHeader ? 20 : 0) + "px")
                })},[_vm._t("title",function(){return [_c('div',{class:_vm.$style['pt-dialog__title']},[(_vm.iconKey)?_c('pt-icon',{class:_vm.$style['pt-dialog__title__icon'],style:({
                                width: '32px',
                                height: '32px'
                            }),attrs:{"icon":_vm.iconKey}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.title))])],1),(_vm.subtitle)?_c('div',{class:_vm.$style['pt-dialog__subtitle']},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()]}),(_vm.showClose)?_c('button',{class:_vm.$style['pt-dialog__headerbtn'],attrs:{"aria-label":"close","type":"button"},on:{"click":_vm.handleClose}},[_c('pt-icon',{class:[_vm.$style['pt-dialog__close'], _vm.$style['pt_icon__close'], 'pt-icon'],attrs:{"icon":"pt-icon-clear"}})],1):_vm._e()],2),(_vm.rendered)?[_c('div',{class:_vm.$style['pt-dialog__body'],style:(_vm.hasScroll ? { maxHeight: _vm.customBodyHeight } : '')},[(_vm.hasScroll)?[_c('pt-scrollbar',{style:({ maxHeight: _vm.customBodyHeight })},[_vm._t("default")],2)]:[_vm._t("default")]],2)]:_vm._e(),_c('div',{class:_vm.$style['pt-dialog__footer'],style:({
                    paddingBottom: ((_vm.hasFooter ? 40 : 16) + "px")
                })},[(_vm.hasFooter)?[_vm._t("footer",function(){return [_c('pt-button',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showCancelButton),expression:"showCancelButton"}],attrs:{"color":_vm.cancelButtonInfo.color || 'darkblue',"type":"outline"},on:{"click":_vm.handleClose}},'pt-button',_vm.cancelButtonInfo,false),[_vm._v(_vm._s(_vm.cancelText))]),_c('pt-button',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirmButton),expression:"showConfirmButton"}],class:_vm.$style['pt-dialog__footer__button__confirm'],attrs:{"color":_vm.confirmButtonInfo.color || 'green'},on:{"click":_vm.handleConfirm}},'pt-button',_vm.confirmButtonInfo,false),[_vm._v(_vm._s(_vm.confirmText))])]})]:_vm._e()],2)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }